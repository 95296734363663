<template>
  <div>
    <validation-observer ref="testimonial-observer">
      <div class="w-50 mb-2">
        <b-form-group>
          <label for="i-mentee-fullname" class="font-weight-bolder">
            {{ $t('Mentee fullname') }} <required-field />
          </label>
          <validation-provider name="menteeFullname" rules="required|min:3|max:200" mode="passive" v-slot="{ errors }">
            <b-form-input id="i-mentee-fullname" type="text" v-model="menteeFullname" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100 mb-2">
        <b-form-group>
          <label for="i-mentee-wishlist" class="font-weight-bolder">
            {{ $t('Testimonial text') }} <required-field />
          </label>
          <validation-provider name="testimonialText" rules="required|min:3|max:1000" mode="passive" v-slot="{ errors }">
            <b-form-textarea id="i-testimonial-text" v-model="testimonialText" rows="3" no-resize />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-50">
        <b-form-group>
          <label for="i-testimonial-date" class="font-weight-bolder">
            {{ $t('Testimonial date') }} <required-field />
          </label>
          <validation-provider name="testimonialDate" rules="required" mode="passive" v-slot="{ errors }">
            <cleave
              id="testimonialDate"
              v-model="testimonialDate"
              class="form-control"
              :raw="false"
              :options="cleaveOptions.date"
              :placeholder="$t('DD/MM/YYYY')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import RequiredField from '@mentoring-platform/views/components/blocks/RequiredField.vue'
import RequiredFieldExplanation from '@mentoring-platform/views/components/blocks/RequiredFieldExplanation.vue'
import { required, min, max } from '@mentoring-platform-validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    Cleave,
    RequiredField,
    RequiredFieldExplanation,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    testimonial: {
      type: Object
    }
  },
  data() {
    return {
      menteeFullname: null,
      testimonialText: null,
      testimonialDate: null,
      cleaveOptions: {
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y']
        },
      }
    }
  },
  created() {
    if(this.testimonial) {
      this.menteeFullname = this.testimonial.mentee_fullname
      this.testimonialText = this.testimonial.statement
      this.testimonialDate = this.testimonial.date
    }
  },
  methods: {
    isValid() {
      return this.$refs['testimonial-observer'].validate().then(success => success)
    },
    collectData() {
      return {
        mentee_fullname: this.menteeFullname,
        testimonial_text: this.testimonialText,
        testimonial_date: this.testimonialDate,
      }
    }
  }
};
</script>

<style lang="scss">

</style>
