<template>
  <b-row>
    <b-col
      cols="21"
      xl="6"
      class="d-flex justify-content-between flex-column"
    >
      <div class="d-flex justify-content-start">
        <b-avatar
          class="grayscale-avatar"
          :src="mentor.user.photo_full_path"
          :text="mentorFullname"
          size="104px"
        />
        <div class="d-flex flex-column ml-1">
          <div class="mb-1">
            <h4 class="mb-0">
              {{ mentorFullname }}
            </h4>
            <div>
              <span class="card-text">{{ $t('Registration date') }}</span>:
              <span class="card-text">{{ mentor.created_at | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit'  }) }}</span>
            </div>
            <div>
              <span class="card-text">{{ $t('Mentor ID') }}</span>:
              <span class="card-text">{{ mentor.id }}</span>
            </div>
          </div>
          <div>
            <mentor-availability :mentor="mentor" />
          </div>
        </div>
      </div>
    </b-col>
    <b-col 
      cols="12"
      xl="6"
    >
      <mentor-settings ref="mentor-settings" :mentor="mentor" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCol,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BRow
} from 'bootstrap-vue'
import MentorSettings from '@mentoring-platform/views/components/blocks/MentorSettings.vue'
import MentorAvailability from '@mentoring-platform/views/components/blocks/Mentor/MentorAvailability.vue'
import { formatGreekDate } from '@mentoring-platform/utils/filters'
import { useMentorHelpers } from '@mentoring-platform/composables'

export default {
  components: {
    BAvatar,
    BButton,
    BCol,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BRow,
    MentorAvailability,
    MentorSettings
  },
  filters: {
    formatGreekDate
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    mentorFullname() {
      return this.getMentorVisibleFullName(this.mentor)
    }
  },
  setup() {
    const { getMentorVisibleFullName } = useMentorHelpers()

    return {
      getMentorVisibleFullName
    }
  }
}
</script>

<style lang="scss">
.mentor-availability {
  display: flex;
  flex-direction: column;

  .badge {
    margin-bottom: 1rem;
  }
}
</style>