<template>
  <div id="mentor-settings">
    <validation-observer ref="mentor-settings-observer">
      <b-form-group
        label="Status:"
        label-for="member-from"
        label-cols-md="3"
        class="fix-position"
      >
        <validation-provider
          v-slot="{ errors }"
          name="selectedStatus"
          rules="required"
          mode="lazy"
        >
          <b-form-radio-group
            :options="statusOptions"
            v-model="selectedStatus"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :label="$t('Attribute') + ':'"
        label-for="member-from"
        label-cols-md="3"
        class="fix-position"
      >
        <b-form-checkbox-group
          :options="attributeOptions"
          v-model="attributeChoices"
        />
      </b-form-group>
      <b-form-group
        :label="$t('GWW tag') + ':'"
        label-for="gww-tag"
        label-cols-md="3"
        class="fix-position"
      >
        <b-form-radio-group
          :options="GWWTagOptions"
          v-model="selectedGWWTag"
        />
      </b-form-group>
      <b-form-group
        :label="$t('Member from') + ':'"
        label-for="member-from"
        label-cols-md="3"
      >
        <b-form-input
          id="member-from"
          v-model="member_from"
          class="col-md-6"
        />
        <div v-if="showCTA" class="d-flex flex-wrap mt-2">
          <b-button variant="primary" @click="saveChanges">
            {{ $t('Save') }}
          </b-button>
        </div>
      </b-form-group>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup
} from 'bootstrap-vue'
import { mentorStatuses } from '@/mentoring-platform/constants'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@mentoring-platform-validations'

export default {
  components: {
    BButton,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    showCTA: {
      type: Boolean,
      default: true
    },
    mentor: {
      type: Object
    }
  },
  data() {
    return {
      statusOptions: [
        { text: this.$t('Active'), value: mentorStatuses.MENTOR_STATUS_ACTIVE },
        { text: this.$t('Inactive'), value: mentorStatuses.MENTOR_STATUS_INACTIVE }
      ],
      selectedStatus: null,
      attributeOptions: [
        { text: 'Mentor', value: 'mentor' },
        { text: 'Coach', value: 'coach' }
      ],
      attributeChoices: [],
      GWWTagOptions: [
        { text: this.$t('Yes'), value: 1 },
        { text: this.$t('No'), value: 0 }
      ],
      selectedGWWTag: null,
      member_from: null
    }
  },
  created() {
    if(this.mentor) {
      if(this.statusOptions.find(option => option.value === this.mentor.status)) { // because status can have value other than MENTOR_STATUS_ACTIVE and MENTOR_STATUS_INACTIVE
        this.selectedStatus = this.mentor.status  
      }
      if(this.mentor.is_mentor) {
        this.attributeChoices.push('mentor')
      }
      if(this.mentor.is_coach) {
        this.attributeChoices.push('coach')
      }
      this.selectedGWWTag = this.mentor.is_gww ? 1 : 0
      this.member_from = this.mentor.member_from
    }
  },
  methods: {
    isValid() {
      return this.$refs['mentor-settings-observer'].validate().then(success => success)
    },
    collectData() {
      return {
        status: this.selectedStatus,
        is_gww: this.selectedGWWTag,
        is_mentor: this.attributeChoices.includes('mentor') ? 1 : 0,
        is_coach: this.attributeChoices.includes('coach') ? 1 : 0,
        member_from: this.member_from
      }
    },
    saveChanges() {
      const payload = this.collectData()
      payload.mentor_id = this.mentor.id
      this.$store.dispatch('mentor/mentorEdit', payload)
        .then(reponse => {
          this.$root.$bvToast.toast(this.$t('Data successfully saved'), {
            title: this.$t('Confirmation'),
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          this.$root.$bvToast.toast(Array.isArray(error.errors) && error.errors[0][0] === 'Mentor has running mentorships or pending requests' ? this.$t('Mentor cannot switch to inactive') : this.$t('An error occured, please try again later'), {
            title: this.$t('Caution'),
            variant: 'danger',
            solid: true,
          })
        })
    }
  }
}
</script>

<style lang="scss">
  #mentor-settings {
    .fix-position .col {
      padding-top: calc(0.438rem + 1px);
      padding-bottom: calc(0.438rem + 1px);
      margin-bottom: 0;
    }
  }
</style>