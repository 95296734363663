<template>
  <b-modal
    id="send-message-modal"
    ref="send-message-modal"
    centered
    size="lg"
    :title="$t('Send message')"
    title-class="font-weight-bolder"
    :ok-title="$t('Send message')"
    @ok.prevent="sendMessage"
    ok-only
    @hidden="onHidden"
  >
    <div class="mb-2">
      <b-card-text class="font-weight-bolder">
        {{ $t('Message for prospective mentor') }}
      </b-card-text>
      <validation-observer ref="send-message-modal-observer">
        <validation-provider
          v-slot="{ errors }"
          name="message"
          rules="required|min:3|max:1400"
          mode="lazy"
        >
          <!-- <quill-editor
            v-model="message"
            :options="editorOptions"
          /> -->
          <b-form-textarea
            rows="5"
            no-resize
            v-model="message"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BFormTextarea,
  BModal
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { required, min, max } from '@mentoring-platform-validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCardText,
    BFormTextarea,
    BModal,
    quillEditor,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      message: '',
      editorOptions: { // https://vueup.github.io/vue-quill/guide/options.html#option-attributes
        placeholder: this.$t('Type your message...'),
        theme: 'snow'
      }
    }
  },
  methods: {
    show() {
      this.$refs['send-message-modal'].show()
    },
    onHidden() {
      this.message = ''
    },
    sendMessage() {
      this.$refs['send-message-modal-observer'].validate().then(async success => {
        if(success) {
          this.$store.dispatch('mentor/sendMessageToMentor', { mentor_id: this.mentor.id, message: this.message })
            .then(response => {
              this.$refs['send-message-modal'].hide()
              this.$bus.$emit('message-sent', this.mentor)
              this.$root.$bvToast.toast(this.$t('Message successfully sent'), {
                title: this.$t('Confirmation'),
                variant: 'success',
                solid: true,
              })
            })
            .catch(error => {
              this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
                title: this.$t('Caution'),
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.ql-editor {
  height: 250px;
}
</style>
