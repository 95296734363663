<template>
  <b-card no-body>
    <b-card-body>
      <validation-observer ref="admin-comments-observer">
        <validation-provider
          v-slot="{ errors }"
          name="comments"
          rules="min:3|max:1400"
          mode="lazy"
        >
          <b-form-textarea
            rows="5"
            no-resize
            v-model="comments"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
    </b-card-body>
    <b-card-footer footer-class="card-footer-class">
      <b-button variant="primary" @click="saveComments">
        {{ $t('Save') }}
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BFormTextarea
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min, max } from '@mentoring-platform-validations'

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      comments: null
    }
  },
  created() {
    this.comments = this.mentor.admin_comments
  },
  methods: {
    collectData() {
      return {
        admin_comments: this.comments
      }
    },
    saveComments() {
      this.$refs['admin-comments-observer'].validate().then(async success => {
        if(success) {
          const payload = this.collectData()
          payload.mentor_id = this.mentor.id
          this.$store.dispatch('mentor/mentorEdit', payload)
            .then(reponse => {
              this.$root.$bvToast.toast(this.$t('Data successfully saved'), {
                title: this.$t('Confirmation'),
                variant: 'success',
                solid: true,
              })
            })
          .catch(error => {
            this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
              title: this.$t('Caution'),
              variant: 'danger',
              solid: true,
            })
          })
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.card-footer-class {
  display: flex;
  justify-content: start;
  border: 0;
  padding-top: 0;
}
</style>
