<template>
  <div>
    <b-card :title="$t('Visibility settings')">
      <b-form-checkbox
        v-model="visible"
        name="check-button"
        switch
        inline
      >
        <span v-if="visible">
          {{ $t('Yes') }}
        </span>
        <span v-else>
          {{ $t('No') }}
        </span>
      </b-form-checkbox>
      <b-button variant="primary" @click="saveVisibilityChoice">
        {{ $t('Save choice') }}
      </b-button>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          {{ $t('Testimonials') }}
        </b-card-title>
        <b-button variant="primary" @click="addNewTestimonial">
          {{ $t('Add new testimonial') }}
        </b-button>
      </b-card-header>
      <b-card-body>
        <div v-for="(testimonialItem) in testimonials" :key="testimonialItem.id">
          <existing-testimonial :testimonial="testimonialItem" />
          <hr class="my-2" />
        </div>
      </b-card-body>
    </b-card>
    <add-new-testimonial-modal ref="add-new-testimonial-modal" :mentor="mentor" />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BFormCheckbox
} from "bootstrap-vue";
import AddNewTestimonialModal from "@mentoring-platform/views/components/blocks/MentorAdmin/AddNewTestimonialModal.vue"
import ExistingTestimonial from "@mentoring-platform/views/components/blocks/MentorAdmin/ExistingTestimonial.vue"
import { useTestimonial } from '@/mentoring-platform/composables'

export default {
  components: {
    AddNewTestimonialModal,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    ExistingTestimonial
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      visible: true
    }
  },
  setup(props) {
    const { testimonials, getTestimonials } = useTestimonial()
    getTestimonials(props.mentor.id)

    return {
      testimonials,
      getTestimonials
    }
  },
  created() {
    this.visible = parseInt(this.mentor.testimonials_are_visible) ? true : false
  },
  beforeMount() {
    this.$bus.$on('testimonials-list-needs-refresh', this.refreshList)
  },
  beforeDestroy() {
    this.$bus.$off('testimonials-list-needs-refresh', this.refreshList)
  },
  methods: {
    addNewTestimonial() {
      this.$refs['add-new-testimonial-modal'].show()
    },
    saveVisibilityChoice() {
      this.$store.dispatch('mentor/mentorEdit', {
        mentor_id: this.mentor.id, 
        testimonials_are_visible: this.visible 
      })
        .then(response => {
          this.$root.$bvToast.toast(this.$t('Data successfully saved'), {
            title: this.$t('Confirmation'),
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
            title: this.$t('Caution'),
            variant: 'danger',
            solid: true,
          })
        })
    },
    refreshList() {
      this.getTestimonials(this.mentor.id)
    }
  }
};
</script>

<style scoped>

</style>
