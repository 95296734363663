<template>
  <div class="mentor-availability">
    <b-badge v-if="mentor.is_mentor" pill :variant="mentoringVariant" class="mr-1 mt-1 mt-md-0">
      <b-card-text>
        <feather-icon :icon="mentoringIcon" />
        {{ mentoringText }}
      </b-card-text>
    </b-badge>
    <b-badge v-if="mentor.is_coach" pill :variant="coachingVariant" class="mt-1 mt-md-0">
      <b-card-text>
        <feather-icon :icon="coachingIcon" />
        {{ coachingText }}
      </b-card-text>
    </b-badge>
  </div>
</template>

<script>
import {
  BBadge,
  BCardText
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BCardText
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    mentoringVariant() {
      return this.mentor.is_available_for_mentoring ? 'light-success' : 'light-danger'
    },
    mentoringIcon() {
      return this.mentor.is_available_for_mentoring ? 'CheckIcon' : 'XIcon'
    },
    mentoringText() {
      return this.mentor.is_available_for_mentoring ? this.$t('Mentor is available for mentoring') : this.$t('Mentor is not available for mentoring')
    },
    coachingVariant() {
      return this.mentor.is_available_for_coaching ? 'light-success' : 'light-danger'
    },
    coachingIcon() {
      return this.mentor.is_available_for_coaching ? 'CheckIcon' : 'XIcon'
    },
    coachingText() {
      return this.mentor.is_available_for_coaching ? this.$t('Mentor is available for coaching') : this.$t('Mentor is not available for coaching')
    }
  }
}
</script>

<style lang="scss" scoped>
  .badge svg, .badge i {
    height: 20px;
  }
</style>