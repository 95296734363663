<template>
  <div>
    <page-header-admin class="mb-2" :title="$t('Mentor page')" />
    <b-row>
      <b-col>
        <b-card>
          <mentor-basic-card v-if="mentor" :mentor="mentor" />
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-tabs pills>
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">
              {{ $t('Mentorships') }}
            </span>
          </template>
          <div v-if="mentor" class="mentor-mentorships">
            <current-mentorship :title="$t('Current mentorship')" :mentor="mentor" />
            <previous-mentorships class="previous-mentorships" :mentor="mentor" />
            <mentorship-requests class="mentorship-requests" :mentor="mentor" />
            <rejected-mentorship-requests class="rejected-mentorship-requests" :mentor="mentor" />
          </div>
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="Edit2Icon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">
              {{ $t('Edit profile') }}
            </span>
          </template>
          <template v-if="mentor">
            <b-card class="mb-3">
              <mentoring-languages ref="mentoring-languages" :mentor="mentor" show-modal />
              <b-button variant="primary" class="my-2" @click="saveProfileChanges('mentoring-languages')" >
                {{ $t('Save changes') }}
              </b-button>
            </b-card>
            <b-card class="mb-3">
              <personal-details ref="personal-details" :mentor="mentor" :email-pre-check="false" />
              <b-button variant="primary" class="my-2" @click="saveProfileChanges('personal-details')" >
                {{ $t('Save changes') }}
              </b-button>
            </b-card>
            <b-card class="mb-3">
              <professional-details ref="professional-details" :mentor="mentor" showMentorFields />
              <b-button variant="primary" class="my-2" @click="saveProfileChanges('professional-details')" >
                {{ $t('Save changes') }}
              </b-button>
            </b-card>
            <b-card class="mb-3">
              <mentor-role ref="mentor-role" :mentor="mentor" />
              <b-button variant="primary" class="my-2" @click="saveProfileChanges('mentor-role')" >
                {{ $t('Save changes') }}
              </b-button>
            </b-card>
            <b-card class="mb-3">
              <mentor-profile ref="mentor-profile" :mentor="mentor" />
              <b-button variant="primary" class="my-2" @click="saveProfileChanges('mentor-profile')" >
                {{ $t('Save changes') }}
              </b-button>
            </b-card>
            <required-field-explanation />
          </template>
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="StarIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">
              {{ $t('Testimonials') }}
            </span>
          </template>
          <testimonials v-if="mentor" :mentor="mentor" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="ClipboardIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">
              {{ $t('Comments') }}
            </span>
          </template>
          <admin-comments v-if="mentor" :mentor="mentor" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="MessageSquareIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">
              {{ $t('Sent messages') }}
            </span>
          </template>
          <mentor-messages
            v-if="mentor"
            show-empty
            send-message-button
            :mentor="mentor"
          />
        </b-tab>
      </b-tabs>
    </b-card>
    <!-- <b-row>
      <b-col>
        <b-button variant="danger">
          {{ $t('Delete mentor') }}
        </b-button>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BRow,
  BTab,
  BTabs
} from 'bootstrap-vue'
import MentorBasicCard from '@mentoring-platform/views/components/blocks/MentorAdmin/MentorBasicCard.vue'
import PersonalDetails from '@mentoring-platform/views/components/blocks/PersonalDetails.vue'
import ProfessionalDetails from '@mentoring-platform/views/components/blocks/ProfessionalDetails.vue'
import MentorRole from '@mentoring-platform/views/components/blocks/MentorRole.vue'
import MentorProfile from '@mentoring-platform/views/components/blocks/MentorProfile.vue'
import Testimonials from '@mentoring-platform/views/components/blocks/MentorAdmin/Testimonials.vue'
import { useMentorRepository } from '@mentoring-platform/composables'
import PageHeaderAdmin from '@mentoring-platform/views/components/blocks/PageHeaderAdmin.vue'
import RequiredFieldExplanation from '@mentoring-platform/views/components/blocks/RequiredFieldExplanation.vue'
import CurrentMentorship from '@mentoring-platform/views/components/blocks/MentorMentorships/CurrentMentorship.vue'
import MentorshipRequests from '@mentoring-platform/views/components/blocks/MentorMentorships/MentorshipRequests.vue'
import PreviousMentorships from '@mentoring-platform/views/components/blocks/MentorMentorships/PreviousMentorships.vue'
import RejectedMentorshipRequests from '@mentoring-platform/views/components/blocks/MentorAdmin/RejectedMentorshipRequests.vue'
import AdminComments from '@mentoring-platform/views/components/blocks/MentorAdmin/AdminComments.vue'
import MentorMessages from '@mentoring-platform/views/components/blocks/MentorMessages.vue'
import MentoringLanguages from '@mentoring-platform/views/components/blocks/MentoringLanguages.vue'

export default {
  components: {
    AdminComments,
    BButton,
    BCard,
    BCol,
    BRow,
    BTab,
    BTabs,
    CurrentMentorship,
    MentorBasicCard,
    MentoringLanguages,
    MentorshipRequests,
    MentorMessages,
    PersonalDetails,
    ProfessionalDetails,
    MentorRole,
    MentorProfile,
    PageHeaderAdmin,
    PreviousMentorships,
    RejectedMentorshipRequests,
    RequiredFieldExplanation,
    Testimonials
  },
  data() {
    return {
      mentor: null
    }
  },
  async created() {
    this.mentor = await this.getSpecificMentor(this.$route.params.id)
  },
  beforeMount() {
    this.$bus.$on('new-mentor-language-successfully-added', this.refreshMentor)
  },
  beforeDestroy() {
    this.$bus.$off('new-mentor-language-successfully-added', this.refreshMentor)
  },
  methods: {
    async saveProfileChanges(ref) {
      const isValid = await this.$refs[ref].isValid()
      if (isValid) {
        let payload = this.$refs[ref].collectData()
        if (ref !== 'mentoring-languages') { // DO NOT REMOVE!!!
          payload = { ...payload, ...this.$refs['mentoring-languages'].collectData() }
        }
        payload.mentor_id = this.mentor.id
        this.$store.dispatch('mentor/mentorEdit', payload)
          .then(async () => {
            this.refreshMentor()
            this.$root.$bvToast.toast(this.$t('Data successfully saved'), {
              title: this.$t('Confirmation'),
              variant: 'success',
              solid: true,
            })
          })
          .catch(() => {
            this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
              title: this.$t('Caution'),
              variant: 'danger',
              solid: true,
            })
          })
      }
    },
    async refreshMentor() {
      await this.refreshMentors()
      this.mentor = await this.getSpecificMentor(this.$route.params.id)
    }
  },
  setup() {
    const { getSpecificMentor, refreshMentors } = useMentorRepository()

    return {
      getSpecificMentor,
      refreshMentors
    }
  }
}
</script>

<style lang="scss">
.mentor-mentorships{

  .mentorship-requests .card-body, .previous-mentorships .card-body, .rejected-mentorship-requests .card-body {
    overflow: auto;
  }

  .no-data-card-body {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}
</style>
