<template>
  <div>
    <testimonial-item :testimonial="testimonial" ref="testimonial-item" />
    <b-button variant="primary" class="mr-1" @click="saveChanges" >
      {{ $t('Save changes') }}
    </b-button>
    <b-button variant="outline-danger" @click="deleteTestimonial">
      {{ $t('Delete testimonial') }}
    </b-button>
    <delete-testimonial-modal ref="delete-testimonial-modal" />
  </div>
</template>

<script>
import {
  BButton
} from "bootstrap-vue";
import DeleteTestimonialModal from "@mentoring-platform/views/components/blocks/MentorAdmin/DeleteTestimonialModal.vue"
import TestimonialItem from "@mentoring-platform/views/components/blocks/MentorAdmin/TestimonialItem.vue"
import { useTestimonial } from '@/mentoring-platform/composables'

export default {
  components: {
    BButton,
    DeleteTestimonialModal,
    TestimonialItem
  },
  props: {
    testimonial: {
      type: Object
    }
  },
  data() {
    return {

    }
  },
  setup() {
    const { editTestimonial } = useTestimonial()

    return {
      editTestimonial
    }
  },
  methods: {
    show() {
      this.$refs['add-new-testimonial-modal'].show()
    },
    async saveChanges() {
      const isValid = await this.$refs['testimonial-item'].isValid()
      if(isValid) {
        const payload = this.$refs['testimonial-item'].collectData()
        payload.testimonial_id = this.testimonial.id
        this.editTestimonial(payload)
          .then(response => {
            this.$root.$bvToast.toast(this.$t('Data successfully saved'), {
              title: this.$t('Confirmation'),
              variant: 'success',
              solid: true,
            })
            this.$bus.$emit('testimonials-list-needs-refresh')
          })
          .catch(error => {
            this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
              title: this.$t('Caution'),
              variant: 'danger',
              solid: true,
            })
          })
      }
    },
    deleteTestimonial() {
      this.$refs['delete-testimonial-modal'].show(this.testimonial)
    },
  }
};
</script>

<style lang="scss">

</style>
