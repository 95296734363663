<template>
  <div v-if="mentorMessages.length || showEmpty">
    <b-card :title="$t('Sent messages')">
      <messages-table :messages="mentorMessages" />
      <template v-if="sendMessageButton">
        <b-button
          variant="primary"
          @click="sendMessage"
        >
          {{ $t('Send message') }}
        </b-button>
        <send-message-modal
          v-if="mentor"
          ref="send-message-modal"
          :mentor="mentor"
        />
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
} from 'bootstrap-vue'
import MessagesTable from '@mentoring-platform/views/components/blocks/MessagesTable.vue'
import SendMessageModal from '@mentoring-platform/views/components/blocks/MentorApplication/SendMessageModal.vue'
import { useMentorMessages } from '@mentoring-platform/composables'

export default {
  components: {
    BButton,
    BCard,
    MessagesTable,
    SendMessageModal,
  },
  props: {
    showEmpty: {
      type: Boolean,
      required: false,
    },
    sendMessageButton: {
      type: Boolean,
      required: false,
    },
    mentor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    }
  },
  beforeMount() {
    this.$bus.$on('message-sent', this.getMentorMessages)
  },
  beforeDestroy() {
    this.$bus.$off('message-sent', this.getMentorMessages)
  },
  methods: {
    sendMessage() {
      this.$refs['send-message-modal'].show()
    },
  },
  setup(props) {
    const { mentorMessages, getMentorMessages } = useMentorMessages()
    getMentorMessages(props.mentor)

    return {
      mentorMessages,
      getMentorMessages,
    }
  },
}
</script>
