<template>
  <b-modal
    id="delete-testimonial-modal"
    ref="delete-testimonial-modal"
    centered
    size="lg"
    :title="$t('Confirm deletion')"
    title-class="font-weight-bolder"
    :ok-title="$t('Confirm deletion')"
    @ok.prevent="deletionConfirmed"
    :cancel-title="$t('Cancel')"
    cancel-variant="outline-primary"
  >
    <b-card-text class="p-2">
      {{ $t('Testimonial deletion confirmation message') }}
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BModal
} from "bootstrap-vue";
import { useTestimonial } from '@/mentoring-platform/composables'

export default {
  components: {
    BCardText,
    BModal
  },
  data() {
    return {
      testimonial: null
    }
  },
  setup() {
    const { deleteTestimonial } = useTestimonial()

    return {
      deleteTestimonial
    }
  },
  methods: {
    show(testimonial) {
      this.testimonial = testimonial
      this.$refs['delete-testimonial-modal'].show()
    },
    deletionConfirmed() {
      const payload = {
        testimonial_id: this.testimonial.id
      }
      this.deleteTestimonial(payload)
        .then(response => {
          this.$root.$bvToast.toast(this.$t('Testimonial successfully deleted'), {
            title: this.$t('Confirmation'),
            variant: 'success',
            solid: true,
          })
          this.$refs['delete-testimonial-modal'].hide()
          this.$bus.$emit('testimonials-list-needs-refresh')
        })
        .catch(error => {
          this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
            title: this.$t('Caution'),
            variant: 'danger',
            solid: true,
          })
        })
    }
  }
};
</script>

<style lang="scss">

</style>
