<template>
  <b-modal
    id="add-new-testimonial-modal"
    ref="add-new-testimonial-modal"
    centered
    size="lg"
    :title="$t('Add new testimonial')"
    title-class="font-weight-bolder"
    footer-class="modal-footer-class"
  >
    <testimonial-item ref="testimonial-item" />
    <template #modal-footer class="d-flex justify-content-between">
      <required-field-explanation />
      <b-button variant="primary" @click="addNewTestimonial">
        {{ $t('Save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal
} from "bootstrap-vue";
import RequiredFieldExplanation from '@mentoring-platform/views/components/blocks/RequiredFieldExplanation.vue'
import TestimonialItem from "@mentoring-platform/views/components/blocks/MentorAdmin/TestimonialItem.vue"
import { useTestimonial } from '@/mentoring-platform/composables'

export default {
  components: {
    BButton,
    BModal,
    RequiredFieldExplanation,
    TestimonialItem
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  setup() {
    const { createTestimonial } = useTestimonial()

    return {
      createTestimonial
    }
  },
  methods: {
    show() {
      this.$refs['add-new-testimonial-modal'].show()
    },
    async addNewTestimonial() {
      const isValid = await this.$refs['testimonial-item'].isValid()
      if(isValid) {
        const payload = this.$refs['testimonial-item'].collectData()
        payload.mentor_id = this.mentor.id
        this.createTestimonial(payload)
          .then(response => {
            this.$root.$bvToast.toast(this.$t('Testimonial successfully added'), {
              title: this.$t('Confirmation'),
              variant: 'success',
              solid: true,
            })
            this.$refs['add-new-testimonial-modal'].hide()
            this.$bus.$emit('testimonials-list-needs-refresh')
          })
          .catch(error => {
            this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
              title: this.$t('Caution'),
              variant: 'danger',
              solid: true,
            })
          })
      }
    }
  }
};
</script>

<style lang="scss">
#add-new-testimonial-modal {

  .modal-footer-class {
    justify-content: space-between;
  }

}
</style>
