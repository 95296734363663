<template>
  <div>
    <b-table
      :fields="fields"
      :items="messages"
      responsive
    >
      <template #head()="{label}">
        {{ label | upper }}
      </template>
      <template #cell(date_sent)="{item}">
        {{ item.date_sent | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit' }) }}
      </template>
      <template #cell(admin)="{item}">
        {{ item.admin.name }} {{ item.admin.surname }}
      </template>
      <template #cell(text)="{item}">
        <b-card-text
          class="text-primary cursor-pointer"
          @click="showFullMessage(item)"
        >
          {{ item.text }}
        </b-card-text>
      </template>
    </b-table>
    <message-modal ref="message-modal" />
  </div>
</template>

<script>
import {
  BCardText,
  BTable,
} from 'bootstrap-vue'
import MessageModal from '@mentoring-platform/views/components/blocks/MessageModal.vue'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'

export default {
  components: {
    BCardText,
    BTable,
    MessageModal,
  },
  filters: {
    formatGreekDate,
    upper,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    fields() {
      return [{
        key: 'date_sent',
        label: this.$t('Date'),
      }, {
        key: 'admin',
        label: this.$t('Admin'),
      }, {
        key: 'text',
        label: this.$t('Message'),
      }]
    }
  },
  methods: {
    showFullMessage(message) {
      this.$refs['message-modal'].show(message)
    },
  },
}
</script>
