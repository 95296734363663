var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.messages,"responsive":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm._f("upper")(label))+" ")]}},{key:"cell(date_sent)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatGreekDate")(item.date_sent,{ month: '2-digit', year: 'numeric', day: '2-digit' }))+" ")]}},{key:"cell(admin)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.admin.name)+" "+_vm._s(item.admin.surname)+" ")]}},{key:"cell(text)",fn:function(ref){
var item = ref.item;
return [_c('b-card-text',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){return _vm.showFullMessage(item)}}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])}),_c('message-modal',{ref:"message-modal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }