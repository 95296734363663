<template>
  <b-table
    :fields="fields"
    :items="mentorshipRequests"
    responsive
  >
    <template #head()="{label}">
      {{ label | upper }}
    </template>
    <template #cell(created_at)="{item}">
      {{ item.created_at | formatGreekDate }}
    </template>
    <template #cell(mentee)="{item}">
      <b-card-text
        class="text-primary cursor-pointer"
        @click="$emit('show-mentee-details', item)"
      >
        {{ item.mentee.user.name }} {{ item.mentee.user.surname }}
      </b-card-text>
    </template>
  </b-table>
</template>

<script>
import {
  BCardText,
  BTable
} from 'bootstrap-vue'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'

export default {
  components: {
    BCardText,
    BTable
  },
  filters: {
    formatGreekDate,
    upper
  },
  props: {
    mentorshipRequests: {
      type: Array,
      required: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    fields() {
      return [{
        key: 'created_at',
        label: this.$t('Request date')
      }, {
        key: 'mentee',
        label: this.$t('Mentee')
      }, {
        key: 'reject_reason',
        label: this.$t('Reject reason')
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.mentorship-request-actions {
  min-width: 250px;
}
</style>
